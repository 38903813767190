<template>
  <div class="loginPage">
    <HeadInfo :is-login="true" />
    <div class="loginMain">
      <div class="loginForget">
        <div>
          <img
            src="../../assets/img/forgotuser.png"
            width="216"
            alt="forgotuser"
          >
        </div>
        <div>
          <div class="loginTitle">
            {{ $g("forgetPassword") }}
          </div>
          <div
            class="loginDescription"
            v-html="description"
          />
        </div>
      </div>
      <div class="loginContent">
        <k-alert-msg ref="lblMsg" />
        <KValidationObserver ref="observer">
          <k-form-group
            label-class="require-mark"
            label-suffix="userName"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
          >
            <div class="col-form-label">
              {{ form.userName }}
            </div>
          </k-form-group>
          <k-form-group
            label-class="require-mark"
            label-suffix="code"
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
          >
            <KValidationProvider
              :rules="{ required: true, max: 50 }"
              v-slot="{ errors }"
              slim
              name="code"
            >
              <k-alert
                :show="errors.length > 0"
                variant="warning"
              >
                {{ errors[0] }}
              </k-alert>
              <k-form-input
                id="code"
                v-model="form.code"
                :aria-label="$g('code')"
              />
            </KValidationProvider>
          </k-form-group>

          <k-form-group
            label-for="noneBind"
            label-align-md="right"
            label-cols-md="3"
            content-cols-lg="6"
            content-cols-md="7"
          >
            <div
              class="alert alert-warning"
              v-if="isManMachine"
            >
              {{ $g("validate.required") }}
            </div>
            <ReCaptcha
              @validate="validate"
              @expired="resetCaptcha"
              style="margin-bottom: 25px"
            />
          </k-form-group>
        </KValidationObserver>

        <k-form-group
          label-for="noneBind"
          label-align-md="right"
          label-cols-md="3"
          content-cols-lg="6"
          content-cols-md="7"
          class="wapBtnCenter"
        >
          <k-button
            type="button"
            @click="$router.back(-1)"
            class="mr-1"
          >
            ReSend
          </k-button>
          <k-button
            type="button"
            @click="next"
          >
            Next
          </k-button>
        </k-form-group>
      </div>
    </div>
    <FooterInfo />
  </div>
</template>
<script>
import { sendUserName, verify, getPageDesc } from "@/api/login";
import HeadInfo from "@/layout/headInfo";
import FooterInfo from "@/layout/footerInfo";
import { storeage, ReCaptcha, repResult } from "fe-infrastractures";

export default {
  components: {
    HeadInfo,
    FooterInfo,
    ReCaptcha,
  },
  data() {
    return {
      isManMachine: false,
      isValidateRecaptcha: false,
      sendType: 0,
      description: "",
      pageName: "ForgotPassword-EnterCode",
      form: {
        userName: "",
        code: "",
      },
      localEnv: storeage.getSystemGeneralInfo()?.isLocal
    };
  },

  methods: {
    validate(success) {
      this.isValidateRecaptcha = success;
      if (this.localEnv === false) {
        this.isManMachine = !this.isValidateRecaptcha;
      }
    },
    resetCaptcha() {
      this.isValidateRecaptcha = false;
    },
    async next() {
      const isValid = await this.validateBeforeSave();
      if (isValid) {
        verify(this.form).then((resp) => {
          if (resp.data.status == repResult.success) {
            this.$router.push({
              name: "reset-password",
              query: {
                username: this.form.userName,
              },
            });
          } else {
            this.$refs.lblMsg.message(repResult.faild, resp.message);
          }
        });
      }
    },
    async validateBeforeSave() {
      var isValid = await this.$refs.observer.validate();
      if (this.localEnv === false) {
        this.isManMachine = !this.isValidateRecaptcha;
        isValid = isValid && this.isValidateRecaptcha;
      }
      return isValid;
    },
    resend() {
      let obj = {
        username: this.form.userName,
        sendtype: this.sendType,
      };
      sendUserName(obj).then((resp) => {
        if (resp.data.status == repResult.success) {
          this.$refs.lblMsg.message(repResult.success, this.$g("sendsuccess"));
        } else {
          this.$refs.lblMsg.message(repResult.faild, resp.message);
        }
      });
    },
  },
  mounted() {
    this.sendType = this.$route.query.sendType;
    this.form.userName = this.$route.query.username;
    getPageDesc(this.pageName).then((resp) => {
      this.description = resp.data.description;
    });
  },
};
</script>
